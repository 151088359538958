import dynamic from 'next/dynamic';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { Block, Container } from '../components/styles/blocks';

const Layout = dynamic(() => import('../components/layoutComponent'));
const PlanetLogo = dynamic(() =>
  import('../features/home/banner/planetShip/PlanetLogo'),
);
const GridBackground = dynamic(() =>
  import('../features/home/banner/PiratesGrid/GridBackground'),
);
const GenesisBox = dynamic(() =>
  import('../features/home/genesisPirates/GenesisBox'),
);
const AlternateBox = dynamic(() =>
  import('../features/home/genesisPirates/AlternateBox'),
);
const SpaceMap = dynamic(() => import('../features/home/spaceMap'));
const Team = dynamic(() => import('../features/home/team'), {
  ssr: false,
});
// const HomeModal = dynamic(() => import('../features/home/homeModal'));

export default function Home() {
  return (
    <Layout>
      {/* <HomeModal /> */}
      <PlanetLogo />
      <Block bColor="#000913" position="relative">
        <Container>
          <Block>
            <GenesisBox />
            <AlternateBox />
          </Block>
        </Container>
      </Block>
      <GridBackground />
      <SpaceMap />
      <Team />
    </Layout>
  );
}

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common', 'spacemap'])),
    },
  };
}
